define('donor-elf-admin/routes/user/funds', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      var userId = this.modelFor('user').id;
      return get(this, 'store').query('fund', { filter: { user_id: userId } });
    }
  });
});