define('donor-elf-admin/router', ['exports', 'ember', 'donor-elf-admin/config/environment'], function (exports, _ember, _donorElfAdminConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _donorElfAdminConfigEnvironment['default'].locationType,
    rootURL: _donorElfAdminConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('organizations');
    this.route('organization', { path: 'organizations/:organizationId' }, function () {
      this.route('edit-org-info', { path: 'org-info/edit' });
      this.route('funds');
      this.route('fund', { path: 'funds/:id' }, function () {
        this.route('actions', function () {
          this.route('merge', function () {
            this.route('with', { path: ':otherFundId' });
          });
          this.route('edit');
        });
        this.route('contacts', function () {
          this.route('contact', { path: ':contactId' });
        });
        this.route('host-identities');
      });
      this.route('mappings', function () {});
      this.route('actions', function () {
        this.route('create-reversal', function () {
          this.route('entity', { path: ':hostId' });
        });
      });
      this.route('users');
    });
    this.route('users', function () {});
    this.route('user', { path: 'users/:user_id' }, function () {
      this.route('info', { path: '/' }, function () {
        this.route('stats', { path: '/' });
      });
      this.route('edit');
      this.route('funds');
    });
    this.route('signups', function () {
      this.route('signup', { path: '/:id' }, function () {
        this.route('audits', { path: '/' });
      });
    });
    this.route('org-signups', function () {
      this.route('org-signup', { path: '/:id' }, function () {
        this.route('edit');
      });
      this.route('new');
    });
    this.route('mailchimp-fund-settings', function () {
      this.route('show', { path: '/:id' }, function () {
        this.route('contacts', function () {
          this.route('show', { path: '/:id' });
        });
      });
    });
    this.route('referrals', function () {
      this.route('referral');
    });
  });

  exports['default'] = Router;
});