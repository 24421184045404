define('donor-elf-admin/components/fund-contacts', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var service = _ember['default'].inject.service;

  /*
    Attrs:
      - fundId
  */

  exports['default'] = _ember['default'].Component.extend({
    store: service(),
    memoryCache: service('memory-cache'),

    fundId: undefined,

    q: undefined,

    highlightedIndex: 0,

    contacts: [],

    filteredContacts: computed('q', 'contacts.[]', function () {
      var _getProperties = this.getProperties('q', 'contacts');

      var q = _getProperties.q;
      var contacts = _getProperties.contacts;

      if (q == null || contacts == null) {
        return contacts;
      }
      q = q.trim().toLowerCase();
      return contacts.filter(function (contact) {
        return get(contact, 'name').toLowerCase().indexOf(q) >= 0;
      });
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties2 = getProperties(this, 'store', 'memoryCache', 'fundId');

      var store = _getProperties2.store;
      var memoryCache = _getProperties2.memoryCache;
      var fundId = _getProperties2.fundId;

      var cacheKey = 'fund/' + fundId + '/contacts';
      var cachedContacts = memoryCache.getCache(cacheKey);
      if (cachedContacts != null) {
        setProperties(this, { contacts: cachedContacts, loading: false });
      } else {
        setProperties(this, { contacts: [], loading: true });
        store.query('contact', { filter: { fund_id: this.get('fundId') } }).then(function (records) {
          var contacts = records.sortBy('name');
          memoryCache.setCache(cacheKey, contacts);
          setProperties(_this, { contacts: contacts, loading: false });
        });
      }
    },

    actions: {
      incrementHighlightedIndex: function incrementHighlightedIndex(incrementBy) {
        var highlightedIndex = get(this, 'highlightedIndex');
        set(this, 'highlightedIndex', highlightedIndex + incrementBy);
      },

      viewHighlightedContact: function viewHighlightedContact() {
        var _getProperties3 = getProperties(this, 'filteredContacts', 'highlightedIndex');

        var filteredContacts = _getProperties3.filteredContacts;
        var highlightedIndex = _getProperties3.highlightedIndex;

        if (highlightedIndex >= 0 && filteredContacts.length > highlightedIndex) {
          var onSelectContact = get(this, 'onSelectContact');
          onSelectContact(filteredContacts[highlightedIndex].id);
        }
      }

    }
  });
});