define('donor-elf-admin/routes/organization/actions/create-reversal/entity', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model(params) {
      var orgId = this.modelFor('organization').get('id');
      var store = get(this, 'store');
      return store.query('entity', { filter: { organization_id: orgId, host_id: params.hostId } }).then(function (records) {
        if (get(records, 'length') === 0) {
          return { entity: null, lineItems: null, hostId: params.hostId };
        } else if (get(records, 'length') === 1) {
          var _ret = (function () {
            var entity = get(records, 'firstObject');
            return {
              v: store.query('line-item', { filter: { organization_id: orgId, entity_id: entity.get('id') }, sort: '-trans_date' }).then(function (lineItems) {
                return {
                  entity: entity,
                  lineItems: lineItems,
                  hostId: params.hostId
                };
              })
            };
          })();

          if (typeof _ret === 'object') return _ret.v;
        } else {
          throw new Error('Multiple entities with host id ' + params.hostId);
        }
      });
    },

    actions: {
      makeReversal: function makeReversal(lineItem) {
        var _this = this;

        if (confirm('Are you sure you want to reverse this transaction?')) {
          var store = get(this, 'store');
          var record = store.createRecord('reverse-transaction', {
            transactionId: get(lineItem, 'transaction_id')
          });
          record.save().then(function () {
            _this.refresh();
          }, function () {
            alert('Whoops! There was an error reversing the transaction');
          });
        }
      }
    }
  });
});