define('donor-elf-admin/routes/org-signups/new', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    queryParams: {
      // DonorElf Organization Id
      organizationId: {}
    },

    model: function model(params) {
      var model = get(this, 'store').createRecord('signup-org');
      if (params.organizationId != null) {
        set(model, 'organizationId', params.organizationId);
      }
      return model;
    },

    actions: {
      afterSave: function afterSave() {
        var model = this.modelFor(this.routeName);
        this.transitionTo('org-signups.org-signup', model);
      },
      onCancel: function onCancel() {
        var model = this.modelFor(this.routeName);
        model.deleteRecord();
        this.transitionTo('org-signups');
      }
    }
  });
});