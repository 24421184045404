define('donor-elf-admin/controllers/users', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['q', 'page'],
    q: '',
    queryInput: '',
    sortBy: ['lastName'],
    sortedUsers: computed.sort('model', 'sortBy'),
    filteredUsers: computed('q', 'sortedUsers', function () {
      var _getProperties = getProperties(this, 'q', 'sortedUsers');

      var q = _getProperties.q;
      var sortedUsers = _getProperties.sortedUsers;

      var query = (q || '').trim().toLowerCase();
      if (query.length === 0) {
        return sortedUsers;
      }
      return sortedUsers.filter(function (x) {
        return get(x, 'nameAndEmailLowerCase').indexOf(query) >= 0;
      });
    }),
    pagedFilteredUsers: computed('filteredUsers', 'page', function () {
      var _getProperties2 = getProperties(this, 'filteredUsers', 'page', 'pageSize');

      var filteredUsers = _getProperties2.filteredUsers;
      var page = _getProperties2.page;
      var pageSize = _getProperties2.pageSize;

      var users = filteredUsers.slice(pageSize * page, pageSize * (page + 1));
      return users;
    }),
    isQueryApplied: computed.notEmpty('q'),
    pageSize: 50,
    page: 0,
    pageCount: computed('pageSize', 'filteredUsers', function () {
      var _getProperties3 = getProperties(this, 'pageSize', 'filteredUsers');

      var pageSize = _getProperties3.pageSize;
      var filteredUsers = _getProperties3.filteredUsers;

      return Math.ceil(filteredUsers.length / pageSize);
    }),
    isPreviousPage: computed('page', function () {
      return get(this, 'page') > 0;
    }),
    isNextPage: computed('page', 'pageCount', function () {
      return get(this, 'page') + 1 < get(this, 'pageCount');
    }),
    debounceApplyFilter: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(500);

          case 2:
            this.send('applyFilter');

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    reset: function reset() {
      setProperties(this, {
        q: '',
        queryInput: ''
      });
    },

    actions: {
      debounceFilter: function debounceFilter() {
        get(this, 'debounceApplyFilter').perform();
      },
      applyFilter: function applyFilter() {
        setProperties(this, {
          'q': get(this, 'queryInput'),
          'page': 0
        });
      },
      nextPage: function nextPage() {
        this.incrementProperty('page');
      },
      previousPage: function previousPage() {
        this.decrementProperty('page');
      }
    }
  });
});