define('donor-elf-admin/helpers/json-stringify', ['exports', 'ember'], function (exports, _ember) {
  exports.jsonStringify = jsonStringify;
  var isBlank = _ember['default'].isBlank;

  function jsonStringify(params /*, hash*/) {
    if (isBlank(params)) {
      return null;
    }
    return JSON.stringify(params);
  }

  exports['default'] = _ember['default'].Helper.helper(jsonStringify);
});