define('donor-elf-admin/controllers/organization/fund/host-identities', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    hostIdentitiesAndHostFunds: computed('model.hostIdentities', 'model.hostFunds', function () {
      var hostIdentities = get(this, 'model.hostIdentities');
      var hostFundsByDatabaseIdAndHostId = get(this, 'model.hostFunds');
      var list = [];
      hostIdentities.forEach(function (hostIdentity) {
        var _getProperties = getProperties(hostIdentity, 'database', 'hostId');

        var database = _getProperties.database;
        var hostId = _getProperties.hostId;

        var hostFund = null;
        if (hostFundsByDatabaseIdAndHostId[database] != null) {
          hostFund = hostFundsByDatabaseIdAndHostId[database][hostId];
        }
        list.push({
          hostIdentity: hostIdentity,
          hostFund: hostFund
        });
      });
      return list;
    })
  });
});