define('donor-elf-admin/routes/organization/fund/contacts/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      return {
        fundId: get(this.modelFor('organization.fund'), 'id')
      };
    },

    actions: {
      selectContact: function selectContact(contact) {
        this.transitionTo('organization.fund.contacts', contact);
      }
    }
  });
});