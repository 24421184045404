define('donor-elf-admin/controllers/organization/users', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var setProperties = _ember['default'].setProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    users: computed.sort('model', 'sortBy'),
    sortBy: computed('sortProperty', 'sortDesc', function () {
      var _getProperties = getProperties(this, 'sortProperty', 'sortDesc');

      var sortProperty = _getProperties.sortProperty;
      var sortDesc = _getProperties.sortDesc;

      var sort = sortProperty;
      if (sortDesc) {
        sort += ':desc';
      }
      return [sort];
    }),
    sortProperty: 'lastName',
    sortDesc: false,
    billable: computed.filterBy('model', 'billable', true),
    orgAdmins: computed.filterBy('model', 'role', 'Org-Admin'),

    actions: {
      sortBy: function sortBy(property) {
        if (get(this, 'sortProperty') === property) {
          this.toggleProperty('sortDesc');
        } else {
          setProperties(this, {
            sortProperty: property,
            sortDesc: false
          });
        }
      }
    }
  });
});