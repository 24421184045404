define('donor-elf-admin/routes/referrals/index', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      var store = this.get('store');
      return store.findAll('user-referral').then(function (referrals) {
        var userIds = [];
        var signupIds = [];
        referrals.toArray().forEach(function (referral) {
          if (_ember['default'].isPresent(referral.get('referredUserId'))) {
            userIds.push(referral.get('referredUserId'));
          }
          if (_ember['default'].isPresent(referral.get('referredSignupId'))) {
            signupIds.push(referral.get('referredSignupId'));
          }
        });
        return _ember['default'].RSVP.hash({
          users: store.query('user', { filter: { id: userIds } }),
          signups: store.query('signup', { filter: { id: signupIds } }),
          userArchives: store.query('user-archive', { filter: { user_id: userIds } })
        }).then(function (results) {
          var usersById = {};
          var signupsById = {};
          var deletedUsersById = {};
          results.users.toArray().forEach(function (user) {
            usersById[user.get('id')] = user;
          });
          results.signups.toArray().forEach(function (signup) {
            signupsById[signup.get('id')] = signup;
          });
          results.userArchives.toArray().forEach(function (userArchive) {
            deletedUsersById[userArchive.get('userId')] = userArchive;
          });
          return referrals.map(function (referral) {
            var referredUser = usersById[referral.get('referredUserId')];
            var referredSignup = signupsById[referral.get('referredSignupId')];
            var deletedUser = deletedUsersById[referral.get('referredUserId')];
            return {
              referral: referral,
              referredUser: referredUser,
              referredSignup: referredSignup,
              deletedUser: deletedUser
            };
          });
        });
      });
    }
  });
});