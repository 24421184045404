define('donor-elf-admin/helpers/format-amount', ['exports', 'ember', 'accounting/format-money'], function (exports, _ember, _accountingFormatMoney) {
  exports['default'] = _ember['default'].Helper.extend({
    compute: function compute(args) {
      var val = args[0];
      var formatVal = (0, _accountingFormatMoney['default'])(val);
      // If there are no cents, then remove them
      formatVal = formatVal.replace(/\.00$/, '');
      if (Number(val) < 0) {
        formatVal = '<span class=\'text-danger\'>' + formatVal + '</span>';
      }
      return _ember['default'].String.htmlSafe(formatVal);
    }
  });
});