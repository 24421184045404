define('donor-elf-admin/components/monthly-active-users-stats', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    store: service(),

    stats: null,

    statsCategories: computed.map('stats', function (item) {
      return item.month + '/' + (item.year - 2000);
    }),

    statsSeries: computed('stats', function () {
      var both = [];
      var loginOnly = [];
      var emailOnly = [];
      var stats = get(this, 'stats');
      for (var i = 0; i < stats.length; i++) {
        var stat = stats[i];
        var total = stat.both + stat.only_login + stat.only_email;
        both.push({ y: stat.both, percent: Math.round(stat.both * 100 / total) });
        loginOnly.push({ y: stat.only_login, percent: Math.round(stat.only_login * 100 / total) });
        emailOnly.push({ y: stat.only_email, percent: Math.round(stat.only_email * 100 / total) });
      }
      return [{
        name: 'Email Only',
        data: emailOnly
      }, {
        name: 'Login & Email',
        data: both
      }, {
        name: 'Login Only',
        data: loginOnly
      }];
    }),

    chartOptions: computed('statsSeries', function () {
      return {
        chart: {
          type: 'area'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: get(this, 'statsCategories')
        },
        yAxis: {
          title: {
            text: null
          }
        },
        tooltip: {
          formatter: function formatter() {
            return this.series.name + ': ' + this.point.y + ' (' + this.point.percent + '%)';
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            marker: {
              enabled: true
            }
          }
        },
        series: get(this, 'statsSeries')
      };
    }),

    getStats: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').queryRecord('generic-stat', { 'type': 'monthly_active_users' }).then(function (data) {
              set(_this, 'stats', get(data, 'stats'));
              _ember['default'].run.scheduleOnce('afterRender', function () {
                _this.$('#monthly-active-users-chart').highcharts(get(_this, 'chartOptions'));
              });
            });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'getStats').perform();
    }
  });
});