define('donor-elf-admin/components/edit-org-signup', ['exports', 'ember', 'ember-concurrency', 'ember-changeset'], function (exports, _ember, _emberConcurrency, _emberChangeset) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;

  /* attrs
     - orgSignup
  
     actions
     - afterSave
     - onCancel
  */
  exports['default'] = _ember['default'].Component.extend({
    store: service(),
    storeCache: service(),

    orgSignup: null,
    changeset: null,
    // keeps track of the array of otherNames on the changeset
    // Ember can't handle an array of strings, so we're changing
    // it to an array of Ember Objects;
    changesetOtherNames: null,
    orgs: [],
    selectedOrg: computed('changeset.organizationId', function () {
      var orgId = get(this, 'changeset.organizationId');
      if (orgId == null) {
        return null;
      }
      return get(this, 'orgs').findBy('id', orgId.toString());
    }),

    selectedOrgInfo: null,
    getSelectedOrgInfo: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var selectedOrgId;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            selectedOrgId = get(this, 'changeset.organizationId');

            if (!(selectedOrgId == null)) {
              context$1$0.next = 4;
              break;
            }

            set(this, 'selectedOrgInfo', null);
            return context$1$0.abrupt('return');

          case 4:
            context$1$0.next = 6;
            return get(this, 'store').query('org-info', { filter: { organization_id: selectedOrgId } }).then(function (orgInfos) {
              var orgInfo = get(orgInfos, 'firstObject');
              set(_this, 'selectedOrgInfo', orgInfo);
              if (_ember['default'].isEmpty(get(_this, 'changeset.name'))) {
                set(_this, 'changeset.name', get(_this, 'selectedOrg.name'));
                set(_this, 'changeset.orgAddUsersName', get(_this, 'selectedOrgInfo.accounting_contact_name'));
                set(_this, 'changeset.orgAddUsersEmail', get(_this, 'selectedOrgInfo.accounting_contact_email'));
              }
            });

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var changeset, changesetOtherNames;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            changeset = get(this, 'changeset');
            changesetOtherNames = get(this, 'changesetOtherNames');

            set(changeset, 'otherNames', changesetOtherNames.mapBy('val'));
            context$1$0.next = 5;
            return changeset.save();

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // setup changeset
      var orgSignup = get(this, 'orgSignup');
      var changeset = new _emberChangeset['default'](orgSignup);
      var orgSignupOtherNames = get(orgSignup, 'otherNames') || [];
      var changesetOtherNames = orgSignupOtherNames.map(function (name) {
        return _ember['default'].Object.create({ val: name });
      });
      set(this, 'changesetOtherNames', changesetOtherNames);
      set(this, 'changeset', changeset);

      // setup orgs
      set(this, 'orgs', get(this, 'storeCache.organizations'));
      get(this, 'getSelectedOrgInfo').perform();
    },

    actions: {
      addName: function addName() {
        get(this, 'changesetOtherNames').pushObject(_ember['default'].Object.create({ val: '' }));
      },
      cancel: function cancel() {
        get(this, 'onCancel')();
      },
      updateName: function updateName(nameIndex, val) {
        var indexItem = get(this, 'changesetOtherNames')[nameIndex];
        set(indexItem, 'val', val);
      },
      selectOrganization: function selectOrganization(org) {
        set(this, 'changeset.organizationId', org.id);
        get(this, 'getSelectedOrgInfo').perform();
      },
      submit: function submit() {
        var _this2 = this;

        get(this, 'save').perform().then(function () {
          get(_this2, 'afterSave')();
        });
      }
    }

  });
});