define('donor-elf-admin/routes/mailchimp-fund-settings/show/contacts/show', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service('store'),

    model: function model(params) {
      var mcSettingInfo = this.modelFor('mailchimp-fund-settings.show');
      var store = get(this, 'store');
      return RSVP.hash({
        member: store.query('mailchimp-member', {
          filter: {
            mc_fund_setting_id: get(mcSettingInfo, 'mcSetting.id'),
            contact_id: params.id
          }
        }).then(function (list) {
          return get(list, 'firstObject');
        })
      });
    }
  });
});