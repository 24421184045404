define('donor-elf-admin/routes/organization', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    storeCache: service('store-cache'),

    model: function model(params) {
      return get(this, 'storeCache').organizationsById[params.organizationId];
    },

    actions: {
      selectFund: function selectFund(fundId) {
        this.transitionTo('organization.fund', fundId);
      }
    }
  });
});