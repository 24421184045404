define('donor-elf-admin/routes/mailchimp-fund-settings/show', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service('store'),

    model: function model(params) {
      var store = get(this, 'store');
      return store.find('mailchimp-fund-setting', params.id).then(function (mcSetting) {
        return RSVP.hash({
          fund: store.query('fund', { filter: { id: get(mcSetting, 'fundId') } }).then(function (funds) {
            return get(funds, 'firstObject');
          }),
          mcSetting: mcSetting,
          interestCategories: store.query('mailchimp-interest-category', {
            filter: {
              mc_fund_setting_id: get(mcSetting, 'id')
            }
          }).then(function (list) {
            return get(list, 'firstObject');
          })
        });
      });
    }
  });
});