define("donor-elf-admin/templates/organization/fund/contacts/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "donor-elf-admin/templates/organization/fund/contacts/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("Contacts:");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "fund-contacts", [], ["fundId", ["subexpr", "@mut", [["get", "model.fundId", ["loc", [null, [2, 23], [2, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelectContact", ["subexpr", "route-action", ["selectContact"], [], ["loc", [null, [2, 52], [2, 82]]], 0, 0]], ["loc", [null, [2, 0], [2, 84]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});