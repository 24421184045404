define('donor-elf-admin/components/contact-line-items', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;

  /*
    Attrs:
      - contactId
  */

  exports['default'] = _ember['default'].Component.extend({
    store: service(),

    contactId: undefined,

    lineItems: undefined,

    loading: false,

    isSmallScreen: false,

    getLineItems: function getLineItems() {
      var _this = this;

      this.setProperties({ lineItems: [], loading: true });
      this.get('store').query('line-item', { filter: { contact_id: this.get('contactId') }, sort: '-trans_date' }).then(function (records) {
        _this.setProperties({ lineItems: records.toArray(), loading: false });
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.getLineItems();
    },

    didInsertElement: function didInsertElement() {
      var isSmallScreen = $(document).width() < 768;
      set(this, 'isSmallScreen', isSmallScreen);
    },

    actions: {
      'delete': function _delete(lineItem) {
        var _this2 = this;

        if (confirm('Are you sure?')) {
          lineItem.destroyRecord().then(function () {
            _this2.getLineItems();
          });
        }
      }
    }
  });
});