define('donor-elf-admin/routes/signups/signup/audits', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    store: service(),

    model: function model() {
      var signup = this.modelFor('signups.signup');
      return get(this, 'store').query('signup-audit', { filter: { signup_id: signup.id } });
    }
  });
});