define('donor-elf-admin/components/fund-name', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;

  /*
    Attrs:
    - fundId
  */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    store: service(),
    storeCache: service(),
    organizations: service(),

    fundId: null,
    fund: null,
    loginUrl: null,

    getFund: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var fundId, cachedFund, fund, orgLoginEmail;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            fundId = get(this, 'fundId');

            if (!_ember['default'].isBlank(fundId)) {
              context$1$0.next = 3;
              break;
            }

            return context$1$0.abrupt('return');

          case 3:
            cachedFund = get(this, 'storeCache.cachedFundsById.' + fundId);

            if (!(cachedFund != null)) {
              context$1$0.next = 7;
              break;
            }

            set(this, 'fund', cachedFund);
            return context$1$0.abrupt('return');

          case 7:
            context$1$0.next = 9;
            return get(this, 'store').findRecord('fund', fundId);

          case 9:
            fund = context$1$0.sent;

            set(this, 'fund', fund);
            context$1$0.next = 13;
            return get(this, 'organizations').getLoginEmail(get(fund, 'organizationId'));

          case 13:
            orgLoginEmail = context$1$0.sent;

            set(this, 'loginUrl', 'https://app.donorelf.com/web/funds/' + get(fund, 'id') + '?loginEmail=' + orgLoginEmail);

          case 15:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'getFund').perform();
    }
  });
});