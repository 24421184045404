define('donor-elf-admin/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Controller.extend({
    storeCache: service('store-cache'),
    session: service(),

    actions: {
      authenticate: function authenticate(username, password) {
        var _this = this;

        get(this, 'session').authenticate('authenticator:devise', username, password).then(function () {
          return get(_this, 'storeCache').populateOrganizations();
        }, function (reason) {
          set(_this, 'errorMessage', reason);
        });
      }
    }
  });
});