define('donor-elf-admin/routes/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    session: service(),

    beforeModel: function beforeModel() {
      if (!get(this, 'session.isAuthenticated')) {
        this.transitionTo('login');
      } else {
        this.transitionTo('organizations');
      }
    }
  });
});