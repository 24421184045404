define('donor-elf-admin/models/user-referral', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    referredUserId: (0, _emberDataAttr['default'])('string'),
    referredByUserId: (0, _emberDataAttr['default'])('string'),
    referredByFirstName: (0, _emberDataAttr['default'])('string'),
    referredByLastName: (0, _emberDataAttr['default'])('string'),
    referredByEmail: (0, _emberDataAttr['default'])('string'),
    referredByOrganizationName: (0, _emberDataAttr['default'])('string'),
    signupDate: (0, _emberDataAttr['default'])('date-only'),
    referralCreditStartDate: (0, _emberDataAttr['default'])('date-only'),
    referralCreditApplied: (0, _emberDataAttr['default'])('boolean'),
    referralCreditAppliedOn: (0, _emberDataAttr['default'])('date-only'),
    referredSignupId: (0, _emberDataAttr['default'])('string'),
    cantFindReferredUser: (0, _emberDataAttr['default'])('boolean'),
    referredUserCancelled: (0, _emberDataAttr['default'])('boolean')
  });
});