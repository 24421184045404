define('donor-elf-admin/routes/signups/signup', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    store: service(),
    storeCache: service(),

    model: function model(params) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        get(_this, 'storeCache').getSignupsPromise().then(function (signups) {
          var signup = signups.find(function (x) {
            return x.id.toString() === params.id;
          });
          if (signup == null) {
            reject();
          } else {
            resolve(signup);
          }
        }, function (reason) {
          reject(reason);
        });
      });
    }
  });
});