define('donor-elf-admin/models/entity', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])(),
    address1: (0, _emberDataAttr['default'])(),
    address2: (0, _emberDataAttr['default'])(),
    city: (0, _emberDataAttr['default'])(),
    state: (0, _emberDataAttr['default'])(),
    zip: (0, _emberDataAttr['default'])(),
    hostId: (0, _emberDataAttr['default'])(),
    hostDb: (0, _emberDataAttr['default'])()
  });
});