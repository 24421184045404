define('donor-elf-admin/models/fund', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend({
    organizationId: (0, _emberDataAttr['default'])(),
    name: (0, _emberDataAttr['default'])(),
    active: (0, _emberDataAttr['default'])(),

    nameLowerCase: computed('name', function () {
      return get(this, 'name').toLowerCase();
    })
  });
});