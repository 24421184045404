define('donor-elf-admin/routes/organization/users', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service('store-cache'),

    model: function model() {
      var orgId = Number(this.modelFor('organization').get('id'));
      return get(this, 'storeCache').getUsersPromise().then(function (users) {
        return users.filter(function (user) {
          return get(user, 'organizationId') === orgId;
        });
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      setProperties(controller, {
        sortProperty: 'lastName',
        sortDesc: false
      });
    }
  });
});