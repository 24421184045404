define('donor-elf-admin/routes/org-signups/org-signup/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('org-signups.org-signup');
    },

    actions: {
      afterSave: function afterSave() {
        this.transitionTo('org-signups.org-signup');
      }
    }
  });
});