define('donor-elf-admin/components/de-input', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].TextField.extend({
    classNames: ['form-control'],
    focusOnInsert: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('focusOnInsert')) {
        this.$().focus();
      }
    },

    keyPress: function keyPress(event) {
      if (event.which === 13) {
        var onEnter = get(this, 'onEnter');
        if (onEnter != null) {
          onEnter(event);
        }
      }
    },

    keyDown: function keyDown(event) {
      if (event.which === 38) {
        var onUpArrow = get(this, 'onUpArrow');
        if (onUpArrow != null) {
          onUpArrow(event);
        }
      } else if (event.which === 40) {
        var onDownArrow = get(this, 'onDownArrow');
        if (onDownArrow != null) {
          onDownArrow(event);
        }
      }
    }
  });
});
/*
  Properties
  - focusOnInsert (default false)

  Actions
  - onEnter
  - onUpArrow
  - onDownArrow
*/