define('donor-elf-admin/routes/organization/fund/host-identities', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model() {
      var _this = this;

      var orgId = get(this.modelFor('organization'), 'id');
      var fund = this.modelFor('organization.fund');
      return get(this, 'storeCache').getHostFundsByDatabaseIdAndHostId(orgId).then(function (hostFunds) {
        return get(_this, 'storeCache').getFundHostIdentitiesByOrgId(orgId).then(function (hostIds) {
          var fundHostIdentities = hostIds[Number(fund.id)];
          return {
            fund: fund,
            hostIdentities: fundHostIdentities,
            hostFunds: hostFunds
          };
        });
      });
    }
  });
});