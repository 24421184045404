define('donor-elf-admin/routes/organization/actions/create-reversal', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service('store-cache'),

    model: function model() {
      var orgId = this.modelFor('organization').get('id');
      // prepopulate the organization funds so they can be shown later
      return get(this, 'storeCache').getFundsByOrganizationId(orgId);
    },

    actions: {
      search: function search(event) {
        this.transitionTo('organization.actions.create-reversal.entity', event.target.value);
      }
    }
  });
});