define('donor-elf-admin/models/line-item', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    transaction_id: (0, _emberDataAttr['default'])(),
    trans_date: (0, _emberDataAttr['default'])('string'),
    trans_type: (0, _emberDataAttr['default'])(),
    memo: (0, _emberDataAttr['default'])(),
    amount: (0, _emberDataAttr['default'])('number'),
    organization_id: (0, _emberDataAttr['default'])('number'),
    fund_id: (0, _emberDataAttr['default'])('number'),
    contact_id: (0, _emberDataAttr['default'])('number'),
    created_at: (0, _emberDataAttr['default'])('date'),
    updated_at: (0, _emberDataAttr['default'])('date'),
    modified_at: (0, _emberDataAttr['default'])('date')
  });
});