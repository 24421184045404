define('donor-elf-admin/models/signup', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend({
    firstName: (0, _emberDataAttr['default'])(),
    lastName: (0, _emberDataAttr['default'])(),
    email: (0, _emberDataAttr['default'])(),
    orgName: (0, _emberDataAttr['default'])(),
    startedAt: (0, _emberDataAttr['default'])(),
    referredByName: (0, _emberDataAttr['default'])('string'),
    referredByOrgName: (0, _emberDataAttr['default'])('string'),
    linkFrom: (0, _emberDataAttr['default'])('string'),
    signupOrgId: (0, _emberDataAttr['default'])('number'),

    firstAndLastName: computed('firstName', 'lastName', function () {
      return get(this, 'firstName') + ' ' + get(this, 'lastName');
    })
  });
});