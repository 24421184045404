define('donor-elf-admin/routes/organization/fund/actions/merge', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model() {
      var _this = this;

      var orgId = get(this.modelFor('organization'), 'id');
      var fund = this.modelFor('organization.fund');
      return get(this, 'storeCache').getFundsByOrganizationId(orgId).then(function (funds) {
        return get(_this, 'storeCache').getFundHostIdentitiesByOrgId(orgId).then(function (hostIds) {
          var fundMappings = hostIds[Number(fund.id)];
          var otherFunds = funds.filter(function (item) {
            return item.id !== fund.id;
          });
          return {
            fund: fund,
            fundMappings: fundMappings,
            otherFundsAndMappings: otherFunds.map(function (item) {
              var mappings = hostIds[Number(item.id)] || [];
              return {
                fund: item,
                mappings: mappings
              };
            })
          };
        });
      });
    }
  });
});