define('donor-elf-admin/routes/user/edit', ['exports', 'ember', 'ember-changeset'], function (exports, _ember, _emberChangeset) {
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() /*params*/{
      return this.modelFor('user');
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      set(controller, 'changeset', new _emberChangeset['default'](model));
    }
  });
});