define('donor-elf-admin/routes/users', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    storeCache: service('store-cache'),

    model: function model() {
      return get(this, 'storeCache').getUsersPromise();
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'queryInput', get(controller, 'q'));
    }
  });
});