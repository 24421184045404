define('donor-elf-admin/controllers/organization/edit-org-info', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        get(this, 'model').save().then(function () {
          _this.transitionToRoute('organization');
        });
      }
    }
  });
});