define('donor-elf-admin/transforms/date-only', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var type = typeof serialized;

      if (type === 'string') {
        // Taken from http://stackoverflow.com/a/17346406/215086
        var tIndex = serialized.indexOf('T');
        if (tIndex >= 0) {
          serialized = serialized.slice(0, tIndex);
        }
        var date = new Date(serialized);
        var offset = date.getTimezoneOffset();
        return new Date(date.getTime() + offset * 60000);
      } else if (type === 'number') {
        return new Date(serialized);
      } else if (serialized == null) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    },

    serialize: function serialize(deserialized) {
      if (deserialized == null) {
        return null;
      } else if (deserialized instanceof Date) {
        return deserialized.getFullYear() + '-' + ('0' + (deserialized.getMonth() + 1)).slice(-2) + '-' + ('0' + deserialized.getDate()).slice(-2);
      } else {
        return deserialized.toString();
      }
    }
  });
});