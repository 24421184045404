define('donor-elf-admin/routes/organization/fund/actions/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    organizations: service(),

    model: function model() {
      var fund = this.modelFor('organization.fund');
      return get(this, 'organizations').getLoginEmail(get(fund, 'organizationId')).then(function (loginEmail) {
        return {
          fund: fund,
          orgLoginEmail: loginEmail
        };
      });
    }
  });
});