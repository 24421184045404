define("donor-elf-admin/templates/org-signups/org-signup/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 55
          }
        },
        "moduleName": "donor-elf-admin/templates/org-signups/org-signup/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        var el2 = dom.createTextNode("Edit Org Signup");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "edit-org-signup", [], ["orgSignup", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 28], [2, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "afterSave", ["subexpr", "route-action", ["afterSave"], [], ["loc", [null, [3, 28], [3, 54]]], 0, 0], "onCancel", ["subexpr", "route-action", ["afterSave"], [], ["loc", [null, [4, 27], [4, 53]]], 0, 0]], ["loc", [null, [2, 0], [4, 55]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});