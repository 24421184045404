define('donor-elf-admin/routes/org-signups/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      return get(this, 'store').findAll('signup-org');
    }
  });
});