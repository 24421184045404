define('donor-elf-admin/components/filter-list', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    query: null,
    filterByProperty: null,
    highlightedIndex: 0,
    itemsToShow: 50,

    mappedItems: computed('items', 'filterByProperty', function () {
      var _getProperties = getProperties(this, 'items', 'filterByProperty');

      var items = _getProperties.items;
      var filterByProperty = _getProperties.filterByProperty;

      return items.map(function (item) {
        return {
          item: item,
          scrubbedValue: (get(item, filterByProperty) || '').toLowerCase()
        };
      });
    }),

    filteredItems: computed('query', 'mappedItems', function () {
      var _getProperties2 = getProperties(this, 'query', 'mappedItems');

      var query = _getProperties2.query;
      var mappedItems = _getProperties2.mappedItems;

      query = (query || '').trim().toLowerCase();
      if (query === '') {
        return mappedItems.map(function (item) {
          return item.item;
        });
      }
      return mappedItems.filter(function (item) {
        return item.scrubbedValue.indexOf(query) >= 0;
      }).map(function (item) {
        return item.item;
      });
    }),

    actions: {
      selectHighlightedIndex: function selectHighlightedIndex() {
        var _getProperties3 = getProperties(this, 'filteredItems', 'highlightedIndex');

        var filteredItems = _getProperties3.filteredItems;
        var highlightedIndex = _getProperties3.highlightedIndex;

        var selectedItem = filteredItems[highlightedIndex];
        this.send('selectItem', selectedItem);
      },
      selectItem: function selectItem(item) {
        var onItemSelected = get(this, 'onItemSelected');
        if (onItemSelected != null) {
          onItemSelected(item);
        }
      },
      incrementHighlightedIndex: function incrementHighlightedIndex(incrementBy) {
        var highlightedIndex = get(this, 'highlightedIndex');
        set(this, 'highlightedIndex', highlightedIndex + incrementBy);
      }
    }
  });
});