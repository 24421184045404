define('donor-elf-admin/controllers/organizations', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['q'],

    q: '',
    highlightedIndex: 0,
    sortByProperty: 'name',
    sortByDirection: 'asc',

    reset: function reset() {
      setProperties(this, {
        q: '',
        highlightedIndex: 0,
        sortByProperty: 'name',
        sortByDirection: 'asc'
      });
    },

    sortBy: computed('sortByProperty', 'sortByDirection', function () {
      return [get(this, 'sortByProperty') + ':' + get(this, 'sortByDirection')];
    }),
    sortedOrganizations: computed.sort('model', 'sortBy'),

    filteredOrganizations: computed('q', 'sortedOrganizations.[]', function () {
      var _getProperties = getProperties(this, 'q', 'sortedOrganizations');

      var q = _getProperties.q;
      var sortedOrganizations = _getProperties.sortedOrganizations;

      q = (q || '').trim().toLowerCase();
      if (q === '') {
        return sortedOrganizations;
      }
      return sortedOrganizations.filter(function (x) {
        var name = get(x, 'name');
        return name && name.toLowerCase().indexOf(q) >= 0;
      });
    }),

    actions: {
      incrementHighlightedIndex: function incrementHighlightedIndex(incrementBy) {
        var highlightedIndex = get(this, 'highlightedIndex');
        set(this, 'highlightedIndex', highlightedIndex + incrementBy);
      },

      viewHighlightedOrganization: function viewHighlightedOrganization() {
        var _getProperties2 = getProperties(this, 'filteredOrganizations', 'highlightedIndex');

        var filteredOrganizations = _getProperties2.filteredOrganizations;
        var highlightedIndex = _getProperties2.highlightedIndex;

        if (highlightedIndex >= 0 && filteredOrganizations.length > highlightedIndex) {
          this.transitionToRoute('organization', filteredOrganizations[highlightedIndex].id);
        }
      },

      sort: function sort(sortByProperty) {
        var currentSortByProperty = get(this, 'sortByProperty');
        if (sortByProperty === currentSortByProperty) {
          if (get(this, 'sortByDirection') === 'asc') {
            set(this, 'sortByDirection', 'desc');
          } else {
            set(this, 'sortByDirection', 'asc');
          }
        } else {
          setProperties(this, {
            sortByProperty: sortByProperty,
            sortByDirection: 'asc'
          });
        }
      }
    }
  });
});