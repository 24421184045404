define('donor-elf-admin/models/fund-mapper', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    organizationId: (0, _emberDataAttr['default'])('number'),
    hostId: (0, _emberDataAttr['default'])(),
    name: (0, _emberDataAttr['default'])(),
    modifiedAt: (0, _emberDataAttr['default'])(),
    dateStrptime: (0, _emberDataAttr['default'])(),
    parentHostId: (0, _emberDataAttr['default'])(),
    uploadRequired: (0, _emberDataAttr['default'])(),
    columnSeparator: (0, _emberDataAttr['default'])(),
    active: (0, _emberDataAttr['default'])(),
    activeFalseVal: (0, _emberDataAttr['default'])(),
    databaseId: (0, _emberDataAttr['default'])(),
    inactivateFundsNotInFile: (0, _emberDataAttr['default'])(),
    headerRowPresent: (0, _emberDataAttr['default'])(),
    createHostFundInstead: (0, _emberDataAttr['default'])()
  });
});