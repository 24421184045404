define('donor-elf-admin/routes/organization/actions/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service('store-cache'),

    model: function model() {
      var orgId = this.modelFor('organization').get('id');
      return get(this, 'storeCache').getOrgSignupsPromise().then(function (orgSignups) {
        var orgSignup = orgSignups.findBy('organizationId', Number(orgId));
        return {
          organizationId: orgId,
          hasOrgSignup: orgSignup != null
        };
      });
    }
  });
});