define('donor-elf-admin/services/store-cache', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({
    store: service(),

    fundsByOrgId: {},
    cachedFundsById: {},

    getFundsByOrganizationId: function getFundsByOrganizationId(orgId) {
      var _this = this;

      orgId = orgId.toString();
      var fundsByOrgId = get(this, 'fundsByOrgId');
      if (fundsByOrgId[orgId] != null) {
        return fundsByOrgId[orgId];
      }
      fundsByOrgId[orgId] = get(this, 'store').query('fund', { filter: { organization_id: orgId } }).then(function (funds) {
        var cachedFundsById = get(_this, 'cachedFundsById');
        funds.forEach(function (fund) {
          cachedFundsById[fund.get('id')] = fund;
        });
        return funds;
      });
      return fundsByOrgId[orgId];
    },

    fundHostIdentitiesByOrgIdPromises: {},

    getFundHostIdentitiesByOrgId: function getFundHostIdentitiesByOrgId(orgId) {
      var currentPromise = get(this, 'fundHostIdentitiesByOrgIdPromises')[orgId];
      if (currentPromise != null) {
        return currentPromise;
      }
      var promise = get(this, 'store').query('host-identity', { filter: { fund_organization_id: orgId } }).then(function (records) {
        var byFundId = {};
        records.forEach(function (record) {
          var fundId = get(record, 'identifiableId');
          byFundId[fundId] = byFundId[fundId] || [];
          byFundId[fundId].push(record);
        });
        return byFundId;
      });
      get(this, 'fundHostIdentitiesByOrgIdPromises')[orgId] = promise;
      return promise;
    },

    hostFundsByOrgIdPromises: {},

    getHostFundsByDatabaseIdAndHostId: function getHostFundsByDatabaseIdAndHostId(orgId) {
      var promise = get(this, 'hostFundsByOrgIdPromises.' + orgId);
      if (promise != null) {
        return promise;
      }
      promise = get(this, 'store').query('host-fund', { filter: { organization_id: orgId } }).then(function (records) {
        var hostFundsByDatabaseIdAndHostId = {};
        records.forEach(function (record) {
          var _getProperties = getProperties(record, 'databaseId', 'hostId');

          var databaseId = _getProperties.databaseId;
          var hostId = _getProperties.hostId;

          hostFundsByDatabaseIdAndHostId[databaseId] = hostFundsByDatabaseIdAndHostId[databaseId] || {};
          hostFundsByDatabaseIdAndHostId[databaseId][hostId] = record;
        });
        return hostFundsByDatabaseIdAndHostId;
      });
      get(this, 'hostFundsByOrgIdPromises')[orgId] = promise;
      return promise;
    },

    // Populated by `populateOrganizations`
    organizations: null,
    // Populated by `populateOrganizations`
    organizationsById: null,

    // It's expected that this is called once in the Application route
    populateOrganizations: function populateOrganizations() {
      var _this2 = this;

      return get(this, 'store').findAll('organization').then(function (orgs) {
        var orgsById = {};
        orgs.forEach(function (org) {
          orgsById[org.get('id')] = org;
        });
        setProperties(_this2, {
          'organizations': orgs,
          'organizationsById': orgsById
        });
        return orgs;
      });
    },

    usersPromise: null,
    usersById: null,

    getUsersPromise: function getUsersPromise() {
      var _this3 = this;

      var usersPromise = get(this, 'usersPromise');
      if (usersPromise != null) {
        return usersPromise;
      }

      usersPromise = get(this, 'store').findAll('user').then(function (users) {
        var usersById = {};
        users.forEach(function (user) {
          usersById[user.get('id')] = user;
        });
        set(_this3, 'usersById', usersById);
        return users;
      });

      set(this, 'usersPromise', usersPromise);
      return usersPromise;
    },

    getUserByIdPromise: function getUserByIdPromise(userId) {
      var _this4 = this;

      return this.getUsersPromise().then(function () {
        return get(_this4, 'usersById')[userId];
      });
    },

    _signupsPromise: null,

    getSignupsPromise: function getSignupsPromise() {
      var _signupsPromise = get(this, '_signupsPromise');
      if (_signupsPromise != null) {
        return _signupsPromise;
      }

      return get(this, 'store').findAll('signup');
    },

    orgSignupsPromise: null,

    getOrgSignupsPromise: function getOrgSignupsPromise() {
      var orgSignupsPromise = get(this, 'orgSignupsPromise');
      if (orgSignupsPromise != null) {
        return orgSignupsPromise;
      }
      orgSignupsPromise = get(this, 'store').findAll('signup-org');
      set(this, 'orgSignupsPromise', orgSignupsPromise);
      return orgSignupsPromise;
    }

  });
});