define('donor-elf-admin/models/contact', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])(),
    emails: (0, _emberDataAttr['default'])(),
    hasEmails: computed('emails', function () {
      var emails = get(this, 'emails');
      if (emails != null && emails.data != null) {
        return emails.data.length > 0;
      } else {
        return false;
      }
    }),
    emailsToString: computed('emails', function () {
      if (get(this, 'hasEmails')) {
        var emails = get(this, 'emails');
        return emails.data.map(function (item) {
          return item.v;
        }).join(', ');
      } else {
        return null;
      }
    }),
    mailchimpInfo: (0, _emberDataAttr['default'])(),
    groups: (0, _emberDataAttr['default'])(),
    groupsToString: computed('groups', function () {
      var groups = get(this, 'groups');
      if (_ember['default'].isEmpty(groups)) {
        return null;
      }
      return groups.join(', ');
    })
  });
});