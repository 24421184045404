define('donor-elf-admin/helpers/cached-fund-name', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Helper.extend({
    storeCache: service('store-cache'),

    compute: function compute(fundId /*, hash*/) {
      var fund = get(this, 'storeCache').cachedFundsById[fundId];
      if (fund == null) {
        return fundId;
      } else {
        return get(fund, 'name');
      }
    }
  });
});