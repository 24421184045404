define('donor-elf-admin/routes/organization/fund/contacts/contact', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model(params) {
      var store = get(this, 'store');
      var contact = store.peekRecord('contact', params.contactId);
      if (contact != null) {
        return contact;
      } else {
        return this.get('store').findRecord('contact', params.contactId);
      }
    }
  });
});