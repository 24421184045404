define('donor-elf-admin/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'nav',
    classNames: ['navbar', 'navbar-full', 'navbar-light'],

    session: service(),

    actions: {
      /*
       * Collapse the navbar after an item is clicked
       */
      navBarItemClicked: function navBarItemClicked(target) {
        // Do not collapse on desktop (i.e. if the button navbar toggle is hidden)
        if (this.$('button.navbar-toggler').is(':hidden')) {
          return;
        }
        // Do not collapse if target is a sub menu
        if (_ember['default'].$(target).hasClass('dropdown-toggle')) {
          return;
        }
        this.$('#navbar-collapse').collapse('hide');
      },

      logout: function logout() {
        get(this, 'session').invalidate();
      }
    }
  });
});