define('donor-elf-admin/routes/organization/edit-org-info', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      var _this = this;

      var orgId = this.paramsFor('organization').organizationId;
      return get(this, 'store').query('org-info', { filter: { organization_id: orgId } }).then(function (orgInfos) {
        var orgInfo = get(orgInfos, 'firstObject');
        if (orgInfo == null) {
          orgInfo = get(_this, 'store').createRecord('org-info', { organization_id: orgId });
        }
        return orgInfo;
      });
    }
  });
});