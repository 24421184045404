define('donor-elf-admin/routes/organization/fund', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model(params) {
      var store = get(this, 'store');
      var fund = store.peekRecord('fund', params.id);
      if (fund != null) {
        return fund;
      } else {
        return this.get('store').findRecord('fund', params.id);
      }
    }
  });
});