define('donor-elf-admin/components/form-field', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  /*  args
     - required
       - model
       - property
       - isEditing
     - optional
       - boolean - whether the property is a boolean value
       - helpText
  */
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-group'],
    model: null,
    property: null,
    boolean: false,
    helpText: null,
    readOnly: false,
    readOnlyValue: computed('model', 'property', 'boolean', function () {
      var _getProperties = getProperties(this, 'model', 'property', 'boolean');

      var model = _getProperties.model;
      var property = _getProperties.property;
      var boolean = _getProperties.boolean;

      var val = get(model, property);
      if (_ember['default'].isBlank(val)) {
        val = '{blank}';
      }
      if (boolean) {
        val = val ? 'Yes' : 'No';
      }
      return val;
    }),

    actions: {
      booleanChanged: function booleanChanged(event) {
        var val = event.target.value === 'true' ? true : false;
        set(this, 'model.' + get(this, 'property'), val);
      }
    }
  });
});