define('donor-elf-admin/controllers/organization/fund/actions/merge/with', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Controller.extend({
    store: service(),
    storeCache: service(),
    merging: false,
    mergingComplete: false,

    actions: {
      merge: function merge() {
        var _this = this;

        if (confirm('Are you sure?')) {
          setProperties(this, {
            merging: true,
            mergingComplete: false
          });
          var record = get(this, 'store').createRecord('merge-fund', {
            keepFundId: get(this, 'model.fund.id'),
            oldFundId: get(this, 'model.otherFund.id'),
            deleteOldFund: false
          });
          record.save().then(function () {
            set(_this, 'mergingComplete', true);
            set(_this, 'storeCache.fundHostIdentitiesByOrgIdPromises', {});
            _this.transitionToRoute('organization.fund.host-identities');
          }, function () {
            alert('Whoops! There was an error merging the funds');
          });
        }
      }
    }
  });
});