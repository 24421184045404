define('donor-elf-admin/routes/mailchimp-fund-settings/contacts/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    store: service('store'),

    model: function model() {
      var mcSettingInfo = this.modelFor('mailchimp-fund-settings.show');
      var store = get(this, 'store');
      return store.query('contact', { filter: { fund_id: get(mcSettingInfo, 'fund.id') } }).then(function (contacts) {
        return contacts;
      });
    }
  });
});