define('donor-elf-admin/components/de-form', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  /*
    args
      - changeset
      - readOnly
      - onSave (action)
  */
  exports['default'] = _ember['default'].Component.extend({
    readOnly: false,
    actions: {
      edit: function edit() {
        set(this, 'readOnly', false);
      },
      submit: function submit() {
        get(this, 'onSave')(get(this, 'changeset'));
        set(this, 'readOnly', true);
      },
      cancel: function cancel() {
        get(this, 'changeset').rollback();
        set(this, 'readOnly', true);
      }
    }
  });
});