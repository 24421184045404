define('donor-elf-admin/routes/mailchimp-fund-settings/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    store: service('store'),

    queryParams: {
      q: {
        replace: true
      }
    },

    model: function model() {
      var store = get(this, 'store');
      return store.findAll('mailchimp-fund-setting').then(function (mcSettings) {
        var fundIds = [];
        var mcSettingsByFundId = {};
        mcSettings.forEach(function (mcSetting) {
          var fundId = get(mcSetting, 'fundId');
          fundIds.push(fundId);
          mcSettingsByFundId[fundId] = mcSetting;
        });
        return store.query('fund', { filter: { id: fundIds } }).then(function (funds) {
          return funds.map(function (fund) {
            return {
              fund: fund,
              mcSetting: mcSettingsByFundId[get(fund, 'id')]
            };
          });
        });
      });
    }
  });
});