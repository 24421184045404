define('donor-elf-admin/controllers/signups/signup/audits', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: ['audit.enteredAt'],
    sortedAuditsAndTimeSpans: computed.sort('auditsAndTimeSpans', 'sortBy'),
    auditsAndTimeSpans: computed('model.[]', function () {
      var auditsAndTimeSpans = [];
      var audits = get(this, 'model').toArray();
      var auditsLength = audits.length;
      for (var i = 0; i < auditsLength; i++) {
        var audit = audits[i];
        var timeSpan = null;
        if (i + 1 < auditsLength) {
          var nextAudit = audits[i + 1];
          var timeSpanMilliseconds = new Date(get(nextAudit, 'enteredAt')) - new Date(get(audit, 'enteredAt'));
          timeSpan = timeSpanMilliseconds / 1000;
        }
        auditsAndTimeSpans.push({ audit: audit, timeSpan: timeSpan });
      }
      return auditsAndTimeSpans;
    })
  });
});