define('donor-elf-admin/controllers/mailchimp-fund-settings/index', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['q'],
    q: '',

    sortBy: ['fund.name:asc'],
    sortedList: computed.sort('model', 'sortBy'),

    filteredList: computed('q', function () {
      var _getProperties = getProperties(this, 'q', 'sortedList');

      var q = _getProperties.q;
      var sortedList = _getProperties.sortedList;

      q = (q || '').trim().toLowerCase();
      if (q === '') {
        return sortedList;
      }
      return sortedList.filter(function (item) {
        var nameLowerCase = get(item, 'fund.nameLowerCase');
        return nameLowerCase.indexOf(q) >= 0;
      });
    })
  });
});