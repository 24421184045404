define('donor-elf-admin/routes/organization/fund/actions/merge/with', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model(params) {
      var fund = this.modelFor('organization.fund');
      var mergeModel = this.modelFor('organization.fund.actions.merge');
      var otherFundsAndMappings = get(mergeModel, 'otherFundsAndMappings');
      var otherFundAndMapping = otherFundsAndMappings.find(function (item) {
        return get(item, 'fund.id') === params.otherFundId;
      });
      return {
        fund: fund,
        fundMappings: get(mergeModel, 'fundMappings'),
        otherFund: get(otherFundAndMapping, 'fund'),
        otherFundMappings: get(otherFundAndMapping, 'mappings')
      };
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'merging', false);
    }
  });
});