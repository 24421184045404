define('donor-elf-admin/routes/organization/funds', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    queryParams: {
      fundQuery: {
        replace: true
      }
    },

    model: function model() {
      var orgId = this.modelFor('organization').id;
      return get(this, 'storeCache').getFundsByOrganizationId(orgId);
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        set(controller, 'fundQuery', '');
      }
    }
  });
});