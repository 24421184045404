define('donor-elf-admin/routes/org-signups/org-signup', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model(params) {
      return get(this, 'storeCache').getOrgSignupsPromise().then(function (orgSignups) {
        return orgSignups.findBy('id', params.id);
      });
    }
  });
});