define('donor-elf-admin/components/org-name', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    storeCache: service('store-cache'),
    store: service('store'),
    organizations: service(),

    showOrgLoginEmail: false,
    loginUrl: null,

    orgName: computed('orgId', function () {
      return get(this, 'organizations').getName(get(this, 'orgId'));
    }),

    loginEmail: null,
    getLoginEmail: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var loginEmail;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'organizations').getLoginEmail(get(this, 'orgId'));

          case 2:
            loginEmail = context$1$0.sent;

            setProperties(this, {
              loginEmail: loginEmail,
              loginUrl: 'https://app.donorelf.com/web/admin?loginEmail=' + loginEmail
            });

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (get(this, 'showOrgLoginEmail')) {
        get(this, 'getLoginEmail').perform();
      }
    }
  });
});