define('donor-elf-admin/components/uses-new-website', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;

  /*
    attrs
     - user - the user model
  */
  exports['default'] = _ember['default'].Component.extend({
    store: service(),
    tagName: 'span',

    user: null,
    usesNewWebsite: false,

    getUsesNewWebsite: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var logins, usesNewWebsite;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').query('login', { filter: { user_id: get(this, 'user.id'), limit: 1 } });

          case 2:
            logins = context$1$0.sent;
            usesNewWebsite = get(logins, 'firstObject.jsonApi') || false;

            set(this, 'usesNewWebsite', usesNewWebsite);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'getUsesNewWebsite').perform();
    }
  });
});