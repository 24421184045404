define('donor-elf-admin/routes/organization/mappings/index', ['exports', 'ember', 'ember-changeset'], function (exports, _ember, _emberChangeset) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() {
      var orgId = this.paramsFor('organization').organizationId;
      var store = get(this, 'store');
      return _ember['default'].RSVP.hash({
        fundMapper: store.query('fund-mapper', { filter: { organization_id: orgId } }).then(function (records) {
          var mapper = get(records, 'firstObject');
          if (mapper != null) {
            return new _emberChangeset['default'](mapper);
          } else {
            return null;
          }
        }),
        transactionMapper: store.query('transaction-mapper', { filter: { organization_id: orgId } }).then(function (records) {
          var mapper = get(records, 'firstObject');
          if (mapper != null) {
            return new _emberChangeset['default'](mapper);
          } else {
            return null;
          }
        })
      });
    },

    actions: {
      saveFundMapper: function saveFundMapper(changeset) {
        var _this = this;

        changeset.save().then(function () {
          _this.refresh();
        });
      },
      saveTransactionMapper: function saveTransactionMapper() /*changeset*/{
        alert("Sorry! Saving the transaction mapper from this site hasn't been implemented yet");
      },
      createFundMapper: function createFundMapper() {
        var _this2 = this;

        var orgId = this.paramsFor('organization').organizationId;
        var mapper = get(this, 'store').createRecord('fund-mapper', { organizationId: orgId });
        mapper.save().then(function () {
          _this2.refresh();
        });
      }
    }
  });
});