define('donor-elf-admin/controllers/user/edit', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend({
    roleOptions: ['User', 'Org-Admin'],
    booleanOptions: [{ val: true, label: 'Yes' }, { val: false, label: 'No' }],
    changeset: null, /* set in route.setupController */

    actions: {
      save: function save() {
        var _this = this;

        get(this, 'changeset').save().then(function () {
          _this.transitionToRoute('user');
        });
      }
    }
  });
});