define('donor-elf-admin/models/user', ['exports', 'ember', 'ember-data', 'ember-data/attr'], function (exports, _ember, _emberData, _emberDataAttr) {
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    email: (0, _emberDataAttr['default'])(),
    organizationId: (0, _emberDataAttr['default'])('number'),
    firstName: (0, _emberDataAttr['default'])(),
    lastName: (0, _emberDataAttr['default'])(),
    role: (0, _emberDataAttr['default'])(),
    billable: (0, _emberDataAttr['default'])(),
    createdAt: (0, _emberDataAttr['default'])('date'),
    nameAndEmailLowerCase: computed('firstName', 'lastName', 'email', function () {
      var _getProperties = getProperties(this, 'firstName', 'lastName', 'email');

      var firstName = _getProperties.firstName;
      var lastName = _getProperties.lastName;
      var email = _getProperties.email;

      return ('' + firstName + lastName + email).toLowerCase();
    }),
    selectedFundId: (0, _emberDataAttr['default'])(),
    selectedMobileFundId: (0, _emberDataAttr['default'])(),
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});