define('donor-elf-admin/routes/organization/fund/actions/merge/index', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model() {
      return this.modelFor('organization.fund.actions.merge');
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'fundQuery', null);
    }
  });
});