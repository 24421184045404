define('donor-elf-admin/helpers/changeset', ['exports', 'ember-changeset/helpers/changeset'], function (exports, _emberChangesetHelpersChangeset) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberChangesetHelpersChangeset['default'];
    }
  });
  Object.defineProperty(exports, 'changeset', {
    enumerable: true,
    get: function get() {
      return _emberChangesetHelpersChangeset.changeset;
    }
  });
});