define('donor-elf-admin/routes/organization/fund/actions/edit', ['exports', 'ember', 'ember-changeset'], function (exports, _ember, _emberChangeset) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    storeCache: service(),

    model: function model() {
      return new _emberChangeset['default'](this.modelFor('organization.fund'));
    },

    actions: {
      save: function save(changeset) {
        var _this = this;

        changeset.save().then(function () {
          _this.transitionTo('organization.fund');
        });
      }
    }
  });
});