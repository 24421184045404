define('donor-elf-admin/services/memory-cache', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Service.extend({
    cache: null,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'cache', {});
    },

    getCache: function getCache(key) {
      return get(this, 'cache')[key];
    },

    setCache: function setCache(key, value) {
      get(this, 'cache')[key] = value;
      return value;
    }
  });
});