define('donor-elf-admin/models/transaction-mapper', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    organizationId: (0, _emberDataAttr['default'])('number'),
    hostId: (0, _emberDataAttr['default'])(),
    transDate: (0, _emberDataAttr['default'])(),
    modifiedAt: (0, _emberDataAttr['default'])(),
    dateStrptime: (0, _emberDataAttr['default'])(),
    transType: (0, _emberDataAttr['default'])(),
    transTypesToSkip: (0, _emberDataAttr['default'])(),
    memo: (0, _emberDataAttr['default'])(),
    amount: (0, _emberDataAttr['default'])(),
    fundId: (0, _emberDataAttr['default'])(),
    entityId: (0, _emberDataAttr['default'])(),
    anonymous: (0, _emberDataAttr['default'])(),
    anonymousTrueVal: (0, _emberDataAttr['default'])(),
    fields: (0, _emberDataAttr['default'])(),
    createdAt: (0, _emberDataAttr['default'])('date'),
    updatedAt: (0, _emberDataAttr['default'])('date'),
    transTypeDefault: (0, _emberDataAttr['default'])(),
    columnSeparator: (0, _emberDataAttr['default'])(),
    earliestTransDate: (0, _emberDataAttr['default'])('date-only'),
    filters: (0, _emberDataAttr['default'])(),
    reverseAmount: (0, _emberDataAttr['default'])('boolean'),
    databaseId: (0, _emberDataAttr['default'])(),
    affectsGoalTransTypes: (0, _emberDataAttr['default'])(),
    affectsBalance: (0, _emberDataAttr['default'])(),
    affectsBalanceTrueVal: (0, _emberDataAttr['default'])(),
    affectsBalanceFalseTransTypes: (0, _emberDataAttr['default'])(),
    fileName: (0, _emberDataAttr['default'])(),
    fileNameLabel: (0, _emberDataAttr['default'])(),
    lastUploadedAt: (0, _emberDataAttr['default'])('date'),
    fileIncludesPledges: (0, _emberDataAttr['default'])('boolean'),
    fundIdAndName: (0, _emberDataAttr['default'])(),
    extensions: (0, _emberDataAttr['default'])(),
    softCreditEntityId: (0, _emberDataAttr['default'])(),
    headerRowPresent: (0, _emberDataAttr['default'])('boolean')
  });
});