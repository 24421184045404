define('donor-elf-admin/models/signup-org', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend({
    organizationId: (0, _emberDataAttr['default'])('number'),
    tntOrganizationId: (0, _emberDataAttr['default'])('number'),
    name: (0, _emberDataAttr['default'])('string'),
    otherNames: (0, _emberDataAttr['default'])(),
    orgAddUsersName: (0, _emberDataAttr['default'])('string'),
    orgAddUsersEmail: (0, _emberDataAttr['default'])('string'),
    orgManuallyAddsUsers: computed.notEmpty('orgAddUsersEmail'),
    signupInstructions: (0, _emberDataAttr['default'])('string'),
    stripePlanId: (0, _emberDataAttr['default'])('string')
  });
});