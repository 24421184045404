define('donor-elf-admin/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    storeCache: service('store-cache'),

    model: function model() {
      if (get(this, 'session.isAuthenticated')) {
        // pre-populate all the organizations
        return get(this, 'storeCache').populateOrganizations();
      } else {
        return [];
      }
    }
  });
});