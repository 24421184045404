define('donor-elf-admin/services/organizations', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({
    storeCache: service(),
    store: service(),

    getName: function getName(orgId) {
      var org = get(this, 'storeCache').organizationsById[orgId];
      return get(org, 'name');
    },

    _loginEmails: {},

    getLoginEmail: function getLoginEmail(orgId) {
      var _this = this;

      var loginEmail = this._loginEmails[orgId];
      if (loginEmail != null) {
        return RSVP.resolve(loginEmail);
      }
      return get(this, 'store').query('org-stat', { filter: { organization_id: orgId } }).then(function (records) {
        var record = get(records, 'firstObject');
        loginEmail = get(record, 'login_email');
        _this._loginEmails[orgId] = loginEmail;
        return loginEmail;
      });
    }
  });
});