define('donor-elf-admin/components/org-info-and-stats', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var moment = window.moment;

  /*
    Attrs:
    - orgId
  */

  exports['default'] = _ember['default'].Component.extend({
    store: service(),

    orgId: null,
    showWeeklyLogins: false,
    showMonthlyActiveUsers: false,

    orgInfo: null,
    orgStat: null,
    orgWeeklyLogins: null,
    orgMonthlyActiveUsers: null,

    orgWeeklyLoginsData: computed.map('orgWeeklyLogins', function (stat) {
      return [moment(stat.week).valueOf(), parseFloat(stat.value)];
    }),

    orgMonthlyActiveUsersCategories: computed.map('orgMonthlyActiveUsers', function (item) {
      return item.month + '/' + (item.year - 2000);
    }),

    orgMonthlyActiveUsersSeries: computed('orgMonthlyActiveUsers', function () {
      var both = [];
      var loginOnly = [];
      var emailOnly = [];
      var stats = get(this, 'orgMonthlyActiveUsers');
      for (var i = 0; i < stats.length; i++) {
        var stat = stats[i];
        var total = stat.both + stat.only_login + stat.only_email;
        both.push({ y: stat.both, percent: Math.round(stat.both * 100 / total) });
        loginOnly.push({ y: stat.only_login, percent: Math.round(stat.only_login * 100 / total) });
        emailOnly.push({ y: stat.only_email, percent: Math.round(stat.only_email * 100 / total) });
      }
      return [{
        name: 'Email Only',
        data: emailOnly
      }, {
        name: 'Login & Email',
        data: both
      }, {
        name: 'Login Only',
        data: loginOnly
      }];
    }),

    weeklyLoginsChartOptions: computed('orgWeeklyLoginsData', function () {
      return {
        chart: {
          type: 'spline'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        series: [{
          name: 'Weekly Logins',
          data: get(this, 'orgWeeklyLoginsData')
        }]
      };
    }),

    monthlyActiveUsersChartOptions: computed('orgMonthlyActiveUsersSeries', 'orgMonthlyActiveUsersCategories', function () {
      return {
        chart: {
          type: 'area'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: get(this, 'orgMonthlyActiveUsersCategories')
        },
        yAxis: {
          title: {
            text: null
          }
        },
        tooltip: {
          formatter: function formatter() {
            return this.series.name + ': ' + this.point.y + ' (' + this.point.percent + '%)';
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            marker: {
              enabled: true
            }
          }
        },
        series: get(this, 'orgMonthlyActiveUsersSeries')
      };
    }),

    getOrgInfo: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').query('org-info', { filter: { organization_id: get(this, 'orgId') } }).then(function (orgInfos) {
              set(_this, 'orgInfo', get(orgInfos, 'firstObject'));
            });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    getOrgStat: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').query('org-stat', { filter: { organization_id: get(this, 'orgId') } }).then(function (orgStats) {
              set(_this2, 'orgStat', get(orgStats, 'firstObject'));
            });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    getOrgWeeklyLogins: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this3 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').queryRecord('generic-stat', { type: 'weekly_logins', organization_id: get(this, 'orgId') }).then(function (data) {
              set(_this3, 'orgWeeklyLogins', get(data, 'stats'));
              _ember['default'].run.scheduleOnce('afterRender', function () {
                _this3.$('#weekly-logins-chart').highcharts(get(_this3, 'weeklyLoginsChartOptions'));
              });
            });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    getOrgMonthlyActiveUsers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this4 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').queryRecord('generic-stat', { type: 'monthly_active_users', organization_id: get(this, 'orgId') }).then(function (data) {
              set(_this4, 'orgMonthlyActiveUsers', get(data, 'stats'));
              _ember['default'].run.scheduleOnce('afterRender', function () {
                _this4.$('#monthly-active-users-chart').highcharts(get(_this4, 'monthlyActiveUsersChartOptions'));
              });
            });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'getOrgInfo').perform();
      get(this, 'getOrgStat').perform();
    },

    actions: {
      showWeeklyLogins: function showWeeklyLogins() {
        set(this, 'showWeeklyLogins', true);
        get(this, 'getOrgWeeklyLogins').perform();
      },
      showMonthlyActiveUsers: function showMonthlyActiveUsers() {
        set(this, 'showMonthlyActiveUsers', true);
        get(this, 'getOrgMonthlyActiveUsers').perform();
      }
    }
  });
});