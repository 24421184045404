define('donor-elf-admin/models/email', ['exports', 'ember-data/model', 'ember-data/attr', 'ember'], function (exports, _emberDataModel, _emberDataAttr, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  var EMAIL_TYPE_LABELS = {
    1: 'New Donations',
    2: 'Weekly Update',
    3: 'Approve Forwarding',
    4: 'Other',
    5: 'Contact Date Alert'
  };

  exports['default'] = _emberDataModel['default'].extend({
    userId: (0, _emberDataAttr['default'])('number'),
    emailTypeId: (0, _emberDataAttr['default'])('number'),
    emailTypeLabel: computed('emailTypeId', function () {
      return EMAIL_TYPE_LABELS[get(this, 'emailTypeId')];
    }),
    sentAt: (0, _emberDataAttr['default'])('date'),
    openedAt: (0, _emberDataAttr['default'])('date'),
    bounced: (0, _emberDataAttr['default'])('boolean'),
    bouncedDescription: (0, _emberDataAttr['default'])()
  });
});