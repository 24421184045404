define("donor-elf-admin/templates/organization/fund/actions/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 16,
              "column": 4
            }
          },
          "moduleName": "donor-elf-admin/templates/organization/fund/actions/edit.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "form-field", [], ["label", "Name", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 25], [8, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "property", "name", "readOnly", ["subexpr", "@mut", [["get", "readOnly", ["loc", [null, [10, 28], [10, 36]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 6], [10, 38]]], 0, 0], ["inline", "form-field", [], ["label", "Active", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [12, 25], [12, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "property", "active", "boolean", true, "readOnly", ["subexpr", "@mut", [["get", "readOnly", ["loc", [null, [15, 28], [15, 36]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 6], [15, 38]]], 0, 0]],
        locals: ["readOnly"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "donor-elf-admin/templates/organization/fund/actions/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "cols-xs-12 col-md-6");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createTextNode("Edit");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 3, 3);
        return morphs;
      },
      statements: [["block", "de-form", [], ["changeset", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 25], [4, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onSave", ["subexpr", "route-action", ["save"], [], ["loc", [null, [5, 22], [5, 43]]], 0, 0], "readOnly", false], 0, null, ["loc", [null, [4, 4], [16, 16]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});