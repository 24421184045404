define('donor-elf-admin/app', ['exports', 'ember', 'donor-elf-admin/resolver', 'ember-load-initializers', 'donor-elf-admin/config/environment'], function (exports, _ember, _donorElfAdminResolver, _emberLoadInitializers, _donorElfAdminConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _donorElfAdminConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _donorElfAdminConfigEnvironment['default'].podModulePrefix,
    Resolver: _donorElfAdminResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _donorElfAdminConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});