define('donor-elf-admin/routes/user/info/stats', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    store: service(),

    model: function model() /*params*/{
      var userId = this.modelFor('user').get('id');
      return _ember['default'].RSVP.hash({
        logins: get(this, 'store').query('login', { filter: { user_id: userId } }),
        emails: get(this, 'store').query('email', { filter: { user_id: userId } })
      });
    }
  });
});