define('donor-elf-admin/components/login-form', ['exports', 'ember'], function (exports, _ember) {
  var getProperties = _ember['default'].getProperties;

  /*
  Inputs:
  
  Actions:
    submit: (username, password)
  */

  exports['default'] = _ember['default'].Component.extend({

    actions: {
      submit: function submit() {
        var _getProperties = getProperties(this, 'username', 'password', 'submit');

        var username = _getProperties.username;
        var password = _getProperties.password;
        var submit = _getProperties.submit;

        submit(username, password);
      }
    }
  });
});