define('donor-elf-admin/components/is-connected-to-donor-hub', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;

  /*
    attrs
     - user - the user model
  */
  exports['default'] = _ember['default'].Component.extend({
    store: service(),
    tagName: 'span',

    user: null,
    isConnected: false,

    getIsConnectedToDonorHub: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var tntFunds;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return get(this, 'store').query('tnt-fund', { filter: { user_id: get(this, 'user.id') } });

          case 2:
            tntFunds = context$1$0.sent;

            set(this, 'isConnected', get(tntFunds, 'length') > 0);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'getIsConnectedToDonorHub').perform();
    }
  });
});